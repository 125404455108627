import Api from '@/services/Api'

export default {
  findAll (pagination) {
    return Api().post('reclamacoes', pagination)
  },
  insert (reclamacao) {
    return Api().post('reclamacao', reclamacao)
  },
  update (reclamacao) {
    return Api().put('reclamacao', reclamacao)
  },
  delete (reclamacao) {
    return Api().delete(`reclamacao?id=${reclamacao.id}`, reclamacao)
  },
  findById (reclamacao) {
    const url = `reclamacao/${reclamacao.id}`
    return Api().get(url)
  },
  findByParam (param, pagination) {
    param = {
      ...param, pagination
    }
    return Api().get('reclamacaoParam', param)
  }
}
